<template>
  <div class="wrapper">
    <div class="body-container row">
      <loader :isLoader="showLoader"></loader>
      <left-panel :activeMenuName="activeMenuName"></left-panel>
      <div class="col-lg-10 right-panel-scroll plr-30 mt-4">
        <div class="row right-panel-header">
          <div class="col-md-4">
            <search-bar
            />
          </div>
          <div class="col-md-4">
          </div>
          <div class="col-md-4">
            <profile-header/>
          </div>
        </div>

        <page-heading-card
            title="Create Quote"
            subTitle=""
            buttonName="See all saved Quotes"
            imageName="create-quote.png"
            addRecordScreenName="quotes"
        />

        <ValidationObserver v-slot="{ validate }" ref="formValidator">
          <form
              @submit.prevent="validate().then(nextOption)"
              class="needs-validation"
              id="add-qo"
          >
            <div class="mt-5 mb-4 section-b">
              <div class="headline-30 mb-4">
                Shipment Details
              </div>

              <lbs-kgs-tab
                  :selectedTab="selectedTab"
                  :addShipmentItem="additionalPieceItem"
                  @change-tab="changeTabName"
              />

              <div class="row mt-3 new-quote-form">
                <div class="col-md-9">
                  <div class="row" v-if="userIsBroker">
                    <div class="form-group has-label col-md-4">
                      <label> Client Name </label>
                      <div class="input-v1">
                        <vue-basic-autocomplete
                            trackby="name"
                            :input-class=" clientNameInvalid ? 'form-control is-invalid' : 'form-control'"
                            placeholder="Select a client"
                            :clear-btn="false"
                            none-find="Client not found"
                            v-model="clientName"
                            :options="clientList"
                            @selected="(event) => selectedClient(event)"
                            @input="(event) => changeClientInputValue(event)"
                        />
                        <span v-if="clientNameInvalid" class="validate-error">The Client Name field is required</span>
                      </div>
                    </div>
                    <div class="form-group has-label col-md-4">
                      <label> Quote Contact </label>
                      <div>
                        <multiselect
                            placeholder="Select a contact"
                            :multiple="true"
                            v-model="quoteContact"
                            :options="contactList"
                            :close-on-select="true"
                            :clear-on-select="true"
                            @input="(event) => selectedContact(event)"
                        >
                        </multiselect>
                        <span v-if="quoteContactInvalid"
                              class="validate-error">The Quote Contact field is required</span>
                      </div>
                    </div>
                    <div class="form-group has-label col-md-4 input-v1">
                      <label> Margin {{ marginDollar ? '$' : '%' }}</label>
                      <ValidationProvider
                          rules="required"
                          name="Margin"
                          v-slot="{ errors, valid, invalid, validated }"
                          v-if="marginDollar"
                      >
                        <input
                            type="number"
                            step="0.01"
                            min="50"
                            :class="[
                            { 'is-valid': valid && validated },
                            { 'is-invalid': invalid && validated },
                            'form-control',
                          ]"
                            placeholder="$00.00"
                            v-model="margin"
                            @wheel="handleWheelEvent"
                            @keydown="handleKeydownEvent"
                        />
                        <span class="validate-error">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <ValidationProvider
                          rules="required"
                          name="Margin"
                          v-slot="{ errors, valid, invalid, validated }"
                          v-else
                      >
                        <input
                            type="number"
                            step="0.01"
                            :class="[
                            { 'is-valid': valid && validated },
                            { 'is-invalid': invalid && validated },
                            'form-control',
                          ]"
                            placeholder="00.00%"
                            v-model="margin"
                            @wheel="handleWheelEvent"
                            @keydown="handleKeydownEvent"
                        />
                        <span class="validate-error">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <div class="input-group-append margin-input-cal input-v1">
                        <span class="input-group-text">
                          <span
                              class="margin-toggle-arrow"
                              v-on:click="marginToggle"
                          >
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M16.1656 5.81608L5.65059 5.81608" stroke="#130F26" stroke-width="1.5"
                                    stroke-linecap="round" stroke-linejoin="round"/>
                              <path
                                  d="M12.75 2.41699C12.75 2.41699 16.1667 4.22449 16.1667 5.81533C16.1667 7.40533 12.75 9.21366 12.75 9.21366"
                                  stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M3.81043 14.0885L14.3254 14.0885" stroke="#130F26" stroke-width="1.5"
                                    stroke-linecap="round" stroke-linejoin="round"/>
                              <path
                                  d="M7.22633 17.4881C7.22633 17.4881 3.80967 15.6797 3.80967 14.0897C3.80967 12.4997 7.22633 10.6914 7.22633 10.6914"
                                  stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                          </span>
                          <div class="circle-margin">
                            <span v-if="marginDollar" class="margin-percentage-dollar">
                              %
                            </span>
                            <span v-else class="margin-percentage-dollar">
                              $
                            </span>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group has-label col-md-5 input-v1">
                      <label> Origin </label>
                      <vue-google-autocomplete
                          ref="originAddress"
                          id="origin-map"
                          classname="form-control"
                          placeholder="City - Postal code"
                          v-model="origin"
                          v-on:placechanged="getOriginData"
                          @keyup="(event) => onKeyUp(event, true)"
                          types="(regions)"
                          :country="country"
                      >
                      </vue-google-autocomplete>
                      <span v-if="originInvalid" class="validate-error"
                      >The Origin field is required</span
                      >
                    </div>
                    <div class="col-md-2 location-arrow-icon">
                      <base-button
                          type="primary"
                          class="btn-theme-EFEBEB"
                          @click.prevent="toggleOriginDestination"
                      >
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M16.1656 5.81608L5.65059 5.81608" stroke="#130F26" stroke-width="1.5"
                                stroke-linecap="round" stroke-linejoin="round"/>
                          <path
                              d="M12.75 2.41699C12.75 2.41699 16.1667 4.22449 16.1667 5.81533C16.1667 7.40533 12.75 9.21366 12.75 9.21366"
                              stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M3.81043 14.0885L14.3254 14.0885" stroke="#130F26" stroke-width="1.5"
                                stroke-linecap="round" stroke-linejoin="round"/>
                          <path
                              d="M7.22633 17.4881C7.22633 17.4881 3.80967 15.6797 3.80967 14.0897C3.80967 12.4997 7.22633 10.6914 7.22633 10.6914"
                              stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </base-button>
                    </div>
                    <div class="form-group has-label col-md-5 input-v1">
                      <label> Destination </label>
                      <vue-google-autocomplete
                          ref="destinationAddress"
                          id="destination-map"
                          classname="form-control"
                          placeholder="City - Postal code"
                          v-model="destination"
                          v-on:placechanged="getDestinationData"
                          types="(regions)"
                          @keyup="(event) => onKeyUp(event, false)"
                          :country="country"
                      >
                      </vue-google-autocomplete>
                      <span v-if="destinationInvalid" class="validate-error">The Destination field is required</span>
                    </div>
                  </div>

                  <additional-piece-view
                      ref="additionalPiece"
                      :createQuote="true"
                      :additionalPieceItem="additionalPieceItem"
                      :selectedTab="selectedTab"
                      :nonStackable="nonStackable"
                      :handleShipmentValidation="handleShipmentValidation"
                      @change-tab-name="changeTabName"
                      @weight-detail="updateWeightDetail"
                  />

                  <hr style="width:100%"/>

                  <div class="headline-30 mb-4">
                    Accessorials
                  </div>

                  <div class="row mb-3">
                    <div class="col-md-4">
                      <p class="accessorials-title">Pick up</p>
                      <div
                          v-for="(item, index) in pickupAccessorials"
                          :key="index"
                      >
                        <base-checkbox
                            class="mb-2"
                            v-model="item.checked"
                            :checked="item.checked"
                        >
                          {{ item.name }}
                        </base-checkbox>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <p class="accessorials-title">Delivery</p>
                      <div
                          v-for="(item, index) in deliveryAccessorials"
                          :key="index"
                      >
                        <base-checkbox
                            class="mb-2"
                            v-model="item.checked"
                            :checked="item.checked"
                        >
                          {{ item.name }}
                        </base-checkbox>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <p class="accessorials-title">Other</p>
                      <div
                          v-for="(item, index) in otherAccessorials"
                          :key="index"
                      >
                        <base-checkbox
                            class="mb-2"
                            v-model="item.checked"
                            :checked="item.checked"
                            @input="selectOtherAccessorials($event, item)"
                        >
                          {{ item.name }}
                        </base-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="sticky-quick-summary">
                    <div class="card card-gray br-16">
                      <div class="card-body">
                        <div
                            class="quick-summary mb-2"
                        >
                          QUICK SUMMARY
                        </div>
                        <div class="summary-text-title">
                          Origin:
                        </div>
                        <div class="summary-text-value">
                          {{ origin }}
                        </div>
                        <div class="summary-text-title">
                          Destination:
                        </div>
                        <div class="summary-text-value">
                          {{ destination }}
                        </div>

                        <div class="summary-text-title">
                          Pieces:
                        </div>
                        <div class="summary-text-value">
                          {{ piecesCount(additionalPieceItem) }}
                        </div>

                        <div class="summary-text-title">
                          Chargeable Weight
                        </div>
                        <div class="summary-text-value">
                          {{
                            weightDetail.totalChargeableWeight != ''
                                ? weightDetail.totalChargeableWeight + ' ' + selectedTab
                                : ''
                          }}
                        </div>

                        <div class="summary-text-title">
                          Volumetric Weight
                        </div>
                        <div class="summary-text-value">
                          {{
                            weightDetail.totalVolumetricWeight != ''
                                ? weightDetail.totalVolumetricWeight + ' ' + selectedTab
                                : ''
                          }}
                        </div>

                        <div class="summary-text-title">
                          Total Shipment Weight
                        </div>
                        <div class="summary-text-value">
                          {{
                            weightDetail.totalWeight != ''
                                ? weightDetail.totalWeight + ' ' + selectedTab
                                : ''
                          }}
                        </div>

                        <!-- Display the title "Pallet spots" and the total number of pallets if available  -->
                        <div class="summary-text-title">
                          Pallet spots
                        </div>
                        <div class="summary-text-value">
                          {{
                            weightDetail.totalNumOfPallets != ''
                                ? weightDetail.totalNumOfPallets
                                : ''
                          }}
                        </div>

                      </div>
                    </div>

                    <base-button
                        class="btn-theme-24876E btn-width-100"
                        @click="findRates"
                    >
                      Get rates
                    </base-button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import LeftPanel from '@/layout/LeftPanel';
import Loader from '@/layout/Loader';
import SearchBar from '@/views/components/SearchBar';
import ProfileHeader from '@/layout/ProfileHeader';
import PageHeadingCard from '@/views/components/PageHeadingCard';
import AdditionalPieceView from '@/views/quotes/AdditionalPieceView';
import Multiselect from 'vue-multiselect';
import {mapGetters} from 'vuex';
import {API, masterAPI} from '@/api/API';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import {formatToLocalDateTime, setClientList} from '@/helpers/utility';
import {deliveryAccessorialsList, otherAccessorialsList, pickupAccessorialsList} from '@/helpers/accessorials';

import LbsKgsTab from '@/views/quotes/LbsKgsTab';

export default {
  components: {
    LeftPanel,
    Loader,
    SearchBar,
    ProfileHeader,
    PageHeadingCard,
    AdditionalPieceView,
    Multiselect,
    VueGoogleAutocomplete,
    LbsKgsTab,
  },
  data: function() {
    return {
      activeMenuName: 'Quotes',
      country: ['us', 'ca'],
      clientName: '',
      quoteContact: [],
      margin: '',
      destination: '',
      origin: '',
      originAddress: '',
      destinationAddress: '',
      clientList: [],
      contactList: [],
      additionalPieceItem: [],
      activeAccessorials: false,
      activeShipment: false,
      marginDollar: false,
      selectedTab: 'lbs',
      selectedClientId: '',
      pickupAccessorials: pickupAccessorialsList,
      deliveryAccessorials: deliveryAccessorialsList,
      otherAccessorials: otherAccessorialsList,
      clientNameInvalid: false,
      quoteContactInvalid: false,
      originInvalid: false,
      destinationInvalid: false,
      handleShipmentValidation: false,
      oldClientName: '',
      showLoader: false,
      nonStackable: false,
      weightDetail: {
        totalChargeableWeight: '',
        totalVolumetricWeight: '',
        totalWeight: '',
        shipmentLoader: false,
        totalNumOfPallets: '',
      },
    };
  },
  watch: {
    client: {
      handler(newClient) {
        this.clientList = setClientList(newClient);
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(['client', 'userIsBroker']),
  },
  methods: {
    /**
     * Toggles the marginDollar property.
     */
    marginToggle() {
      this.marginDollar = !this.marginDollar;
    },
    /**
     * Toggles the activeAccessorials property.
     */
    activateAccessorials() {
      let wasActive = this.activeAccessorials;
      this.activeAccessorials = !wasActive;
    },
    /**
     * Toggles the activeShipment property and resets handleShipmentValidation.
     */
    activateShipment() {
      this.activeShipment = !this.activeShipment;
      this.handleShipmentValidation = false;
    },
    /**
     * Retrieves the shipment item from the additionalPiece component.
     * @returns {Array} - Shipment item list.
     */
    getShipmentItem() {
      let shipmentList = this.$refs.additionalPiece.addShipmentItem;
      return shipmentList;
    },
    /**
     * Validates the shipment form and sets the activeAccessorials property accordingly.
     */
    getValidate() {
      let shipmentList = this.getShipmentItem();
      if (shipmentList.length > 0) {
        let listItem = shipmentList.map((item) => {
          if (
              item.height == '' ||
              item.length == '' ||
              item.weight == '' ||
              item.width == '' ||
              item.quantity == '' ||
              item.quantity == 0
          ) {
            return item;
          }
        }).filter(function(element) {
          return element !== undefined;
        });
        if (listItem.length > 0) {
          this.activeAccessorials = false;
        } else {
          this.activeAccessorials = true;
        }
        return;
      }
    },
    /**
     * Handles the next option button click.
     */
    nextOption() {
      if (this.validateCommonFields()) {
        if (this.activeShipment) {
          this.handleShipmentValidation = true;
        }
        this.activeShipment = true;

        // Perform form validation
        this.$refs.formValidator.validate().then(() => {
          this.getValidate();
        });
      }
    },
    /**
     * Changes the additional piece tab name and updates the additionalPieceItem and selectedTab properties.
     * @param {Array} data - Additional piece item data.
     * @param {string} tab - Selected tab.
     */
    changeTabName(data, tab) {
      this.additionalPieceItem = data;
      this.selectedTab = tab;
      this.$nextTick(() => {
        this.$refs.additionalPiece.chargeableWeightApi();
      });
    },
    /**
     * Finds rates by calling the findLanes API.
     */
    async findRates() {
      if (this.validateCommonFields()) {
        // Perform form validation
        const isFormValid = await this.$refs.formValidator.validate();
        if (isFormValid) {
          this.showLoader = true;
          let additionalPieceList = this.getShipmentItem();
          let items = [];
          additionalPieceList.map((item) => {
            items.push({
              quantity: item.quantity,
              length: item.length,
              width: item.width,
              height: item.height,
              weight: item.weight,
            });
          });

          // Accessing the pickupDate and deliveryDate
          let pickupDate = this.$refs.additionalPiece.pickupDeliveryDate.pickupDate;
          let deliveryDate = this.$refs.additionalPiece.pickupDeliveryDate.deliveryDate;

          let bodyPayload = {
            origin: this.origin,
            destination: this.destination,
            metric: {
              volume: this.selectedTab == 'lbs' ? 'inches' : 'cms',
              weight: this.selectedTab,
            },
            items: items,
            accessorials: this.mergeAccessorials(),
            nonStackable: this.nonStackable,
          };

          // Add metadata conditionally only if both pickupDate and deliveryDate are available
          if (pickupDate && deliveryDate) {

            // Format the dates using moment.js and the utility function
            let formattedPickupDate = formatToLocalDateTime(pickupDate);
            let formattedDeliveryDate = formatToLocalDateTime(deliveryDate);

            // Assign to the payload
            bodyPayload.metadata = {
              pickupDate: formattedPickupDate,
              deliveryDate: formattedDeliveryDate,
            };
          }

          // Conditionally add clientId, contact, and margin based on userIsBroker
          if (this.userIsBroker) {
            bodyPayload.clientId = this.clientName.id;
            bodyPayload.contact = this.getContactByName();
            bodyPayload.margin = {
              type: this.marginDollar ? 'fixed' : 'percentage',
              value: this.margin,
            };
          }

          let response = await masterAPI(
              API.API_ENDPOINT.findLanes,
              API.API_METHOD.post,
              undefined,
              JSON.stringify(bodyPayload),
          );

          this.showLoader = false;

          if (response.status == 200) {
            this.$session.remove('save_quote_details');

            let shipmentMetrics = response.data.shipmentMetrics;

            let quoteDetail = {
              clientName: this.clientName,
              quoteContact: this.quoteContact,
              margin: this.margin,
              origin: this.origin,
              destination: this.destination,
              addShipmentItem: shipmentMetrics.items,
              selectedTab: shipmentMetrics.metric.weight,
              marginDollar: this.marginDollar,
              response: response.data,
              pickupAccessorials: this.pickupAccessorials,
              deliveryAccessorials: this.deliveryAccessorials,
              otherAccessorials: this.otherAccessorials,
              quoteRequest: response.data.quoteRequest,
            };
            if (pickupDate && deliveryDate) {
              let formattedPickupDate = formatToLocalDateTime(pickupDate);
              let formattedDeliveryDate = formatToLocalDateTime(deliveryDate);
              quoteDetail.metadata = {
                pickupDate: formattedPickupDate,
                deliveryDate: formattedDeliveryDate
              };
            }
            this.$session.set('save_quote_details', JSON.stringify(quoteDetail));
            this.$router.push({path: '/quote-result', name: 'quote-result'}).catch(() => {});
          } else {
            this.$toast.error(response.data.message);
          }
        }
      }
    },

    resetAccessorials() {
      let mergeArray = [
        ...this.pickupAccessorials,
        ...this.deliveryAccessorials,
        ...this.otherAccessorials,
      ];

      mergeArray.forEach((item) => {
        item.checked = false;
      });
    },
    /**
     * Merges the pickup, delivery, and other accessorials into a single array.
     * @returns {Array} - The merged accessorials array.
     */
    mergeAccessorials() {
      let mergeArray = [
        ...this.pickupAccessorials,
        ...this.deliveryAccessorials,
        ...this.otherAccessorials,
      ];

      let accessorialsDetail = [];
      mergeArray.map((item) => {
        if (item.checked) {
          accessorialsDetail.push(item.key);
        }
      });
      return accessorialsDetail;
    },
    /**
     * Retrieves the contact object based on the selected contact names.
     * @returns {Object} - The contact object.
     */
    getContactByName: function() {
      let contact = [];
      let selectedContact = this.clientName.contacts;
      this.quoteContact.map((item) => {
        selectedContact.map((contactsObj) => {
          if (item == contactsObj.name) {
            contact.push(contactsObj);
          }
        });
      });
      let contactObj = {};
      if (contact.length != 0) {
        contactObj = contact[0];
      }
      return contactObj;
    },
    /**
     * Toggles the origin and destination values and updates the corresponding input fields.
     */
    toggleOriginDestination() {
      let oldOrgin = this.origin;
      this.origin = this.destination;
      this.destination = oldOrgin;
      this.$refs.originAddress.update(this.destination);
      this.$refs.destinationAddress.update(this.origin);
    },
    /**
     * Event handler for the selectedContact event. Resets the quoteContactInvalid flag.
     */
    selectedContact() {
      this.quoteContactInvalid = false;
    },
    /**
     * Event handler for the changeClientInputValue event. Clears the quoteContact array if the client name input value is empty.
     * @param {string} value - Client name input value.
     */
    changeClientInputValue(value) {
      if (value == '') {
        this.quoteContact = [];
      }
    },
    /**
     * Event handler for the selectedClient event. Updates the contactList, clientNameInvalid, and selectedClientId properties.
     * @param {Object} selectItem - Selected client object.
     */
    selectedClient(selectItem) {
      this.contactList = [];
      this.clientNameInvalid = false;
      this.selectedClientId = selectItem.id;
      let marginData = selectItem.margin;
      this.margin = marginData.value;
      if (marginData.type == 'fixed') {
        this.marginDollar = true;
      } else {
        this.marginDollar = false;
      }

      selectItem.contacts.map((item) => {
        this.contactList.push(item.name);
      });

      if (this.oldClientName != selectItem.name) {
        this.quoteContact = [];
        this.oldClientName = selectItem.name;
      }
    },
    /**
     * Event handler for the onKeyUp event on origin and destination inputs. Clears the origin or destination value if the input is empty.
     * @param {Event} event - Keyup event.
     * @param {boolean} isOrigin - Flag indicating if the origin input triggered the event.
     */
    onKeyUp(event, isOrigin) {
      if (event.target.value == '') {
        if (isOrigin) {
          this.origin = '';
        } else {
          this.destination = '';
        }
      }
    },
    /**
     * Retrieves the origin data from the Google Autocomplete component and updates the origin and originInvalid properties.
     * @param {string} address - Origin address.
     * @param {Object} placeResultData - Place result data object.
     */
    getOriginData(address, placeResultData) {
      this.origin = placeResultData.formatted_address;
      this.originInvalid = false;
    },
    /**
     * Retrieves the destination data from the Google Autocomplete component and updates the destination and destinationInvalid properties.
     * @param {string} address - Destination address.
     * @param {Object} placeResultData - Place result data object.
     */
    getDestinationData(address, placeResultData) {
      this.destination = placeResultData.formatted_address;
      this.destinationInvalid = false;
    },
    /**
     * Selects or deselects an other accessorial item.
     * @param {boolean} e - The selection state.
     * @param {Object} item - The selected accessorial item.
     */
    selectOtherAccessorials(e, item) {
      if (item.key == 'nonStackable') {
        this.nonStackable = e;
        this.$refs.additionalPiece.chargeableWeightApi();
      }
    },
    handleWheelEvent(event) {
      event.preventDefault();
    },
    handleKeydownEvent(event) {
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        event.preventDefault();
      }
    },
    /**
     * Common validation logic for checking client, quote contact, origin, and destination.
     * @returns {boolean} - Returns true if validation passes, false otherwise.
     */
    validateCommonFields() {
      if (this.userIsBroker && this.clientName == '' && this.quoteContact.length == 0) {
        this.clientNameInvalid = true;
        this.quoteContactInvalid = true;
      } else if (this.userIsBroker && this.clientName == '') {
        this.clientNameInvalid = true;
      } else if (this.userIsBroker && this.quoteContact.length == 0) {
        this.quoteContactInvalid = true;
      } else if (this.origin == '') {
        this.originInvalid = true;
      } else if (this.destination == '') {
        this.destinationInvalid = true;
      } else {
        return true; // Validation passed
      }
      return false; // Validation failed
    },
    /**
     * Calculates the total count of pieces based on the quantity of each item.
     * @param {Array<Object>} items - An array of items, each containing a 'quantity' property.
     * @returns {number} - The total count of pieces.
     */
    piecesCount(items) {
      let sum = 0;
      items.forEach(item => {
        if (!isNaN(item.quantity)) { // Check if quantity is a number
          sum += parseInt(item.quantity);
        }
      });

      return isNaN(sum) ? '' : sum;
    },
    /**
     * Sets the updated weight details.
     */
    updateWeightDetail(data) {
      this.weightDetail = data;
    },
  },
  mounted() {
    this.$refs.originAddress.focus();
    this.$refs.destinationAddress.focus();
  },
  created() {
    this.resetAccessorials();
    this.$nextTick(() => {
      this.additionalPieceItem = this.getShipmentItem();
    });
  },
};
</script>

<style>
.create-new-quote-page {
  margin-top: 10px;
  margin-bottom: 20px;
}

.new-quote-card .card-body {
  padding: 10px;
}

.new-quote-card .form-group {
  margin-bottom: 0rem;
}

.quote-collapse {
  border-radius: 4px !important;
}

.quote-collapse .card-header {
  border-radius: 4px !important;
  background: #004225 !important;
  padding: 5px !important;
}

.quote-collapse .card-header h5 {
  color: white !important;
}

.accessorials-title {
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #333334;
}

.accessorials-title-blank {
  margin-bottom: 2.6rem !important;
}

.get-rate {
  text-align: right;
  margin-bottom: 15px;
}

.get-rate-btn {
  background-color: #f0c03d !important;
  border: 1px solid #f0c03d !important;
  border-radius: 4px !important;
}

.close-icon {
  position: absolute;
  top: 43px;
  right: -5px;
  cursor: pointer;
}

.add-additional-piece {
  background-color: #004225 !important;
  border: 1px solid #004225 !important;
  border-radius: 4px !important;
  color: #fff !important;
}

.shipment-detail-footer .fa {
  padding-left: 7px;
}

.next-btn {
  text-align: right;
  margin-bottom: 15px;
  margin-top: 15px;
}

.next-btn button {
  background-color: #f0c03d !important;
  border: 1px solid #f0c03d !important;
  border-radius: 4px !important;
}

.text-right {
  text-align: right;
}

.nav-pills.nav-pills-info .nav-item .nav-link {
  color: #004225 !important;
}

.nav-pills .nav-link {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.delete-icon {
  margin-top: 2rem;
  cursor: pointer;
}

.locations-input-margin {
  margin-right: 10px !important;
  margin-left: 25px !important;
}

.margin-percentage-dollar {
  color: white;
  width: 10px;
}
</style>
